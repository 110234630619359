<template>
	<div :class="themeClass">
		<div class="breadcrumbDiv margin-t-20 margin-b-10">
			<div class="flex-row font-color-999">
				<span class="margin-top-3px">您的位置：</span>
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/businessBuy' }">企业购</el-breadcrumb-item>
					<el-breadcrumb-item>
						<div class="color-theme">议价单</div>
					</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="yijia-content width-fill">
			<div class="padding20-t-lr">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="审核中" name="first">
						<AuditBuyingPrice />
					</el-tab-pane>
					<el-tab-pane label="待确认" name="second">	
						<IsconfirmBuyingPrice />
					</el-tab-pane>
					<el-tab-pane label="审核不同意" name="third">
						<DisagreeBuyingPrice />
					</el-tab-pane>
					<el-tab-pane label="生效" name="four">
						<AgreeBuyingPrice />
					</el-tab-pane>
					<el-tab-pane label="全部议价" name="five">
						<AllBuyingPrice />
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>
<script>
	import AuditBuyingPrice from "@/views/businessBuy/components/AuditBuyingPrice.vue"; //审核中
	import IsconfirmBuyingPrice from "@/views/businessBuy/components/IsconfirmBuyingPrice.vue"; //客户待确认
	import DisagreeBuyingPrice from "@/views/businessBuy/components/DisagreeBuyingPrice.vue"; //平台审核不同意
	import AgreeBuyingPrice from "@/views/businessBuy/components/AgreeBuyingPrice.vue"; //平台审核同意，生效
	import AllBuyingPrice from "@/views/businessBuy/components/AllBuyingPrice.vue"; //全部议价单
	import {
		mapGetters
	} from "vuex";
	export default {
		components: {
			AuditBuyingPrice,
			IsconfirmBuyingPrice,
			DisagreeBuyingPrice,
			AgreeBuyingPrice,
			AllBuyingPrice
		},
		data() {
			return {
				activeName: "first",
				list2: [{
						time: "2020-09-23",
						orderID: "20200923990",
						shopname: "聚材通自营店",
						goodsPic: "",
						productionPlant: "收货地址：乌鲁木齐白杨沟4000号第一工程工地",
						engineering: "乌鲁木齐白杨沟4000号第一工程工地",
						delivery: "代送",
						purchaseQuantity: "1068",
						payment: "888",
						payStatus: 0,
						weight: "1000吨",
					},
					{
						time: "2020-09-23",
						orderID: "20200923990",
						shopname: "聚材通自营店",
						goodsPic: "",
						productionPlant: "收货地址：乌鲁木齐白杨沟4000号第一工程工地",
						engineering: "乌鲁木齐白杨沟4000号第一工程工地",
						delivery: "代送",
						purchaseQuantity: "1068",
						payment: "888",
						payStatus: 1,
						weight: "1000吨",
					},
				],
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.padding-b-7 {
		padding-bottom: 7px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.backgroundcolor-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.color00CC0 {
		color: #00cc00;
	}

	.colo999999 {
		color: #999999;
	}

	.line-height27 {
		line-height: 27px;
	}

	.line-height46 {
		line-height: 46px;
	}

	.margin5-0 {
		margin: 5px 0;
	}

	.margin-right-13 {
		margin-right: 13px;
	}

	.padding20-t-lr {
		padding: 20px 20px 50px 20px;
	}

	.margin25-auto {
		margin: 25px auto;
	}

	.bargainingApplication {
		width: 100%;
		margin: 15px auto;
		text-align: center;
	}

	.yijia-content {
		width: 1100px;
		min-height: 700px;
		//   height:  580px;
		background: #f8f9fe;
		margin-bottom: 100px;
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.font-color-FF0033 {
		color: #ff0033;
	}

	.padding-14 {
		padding: 14px;
	}

	.item-title {
		box-sizing: border-box;
		height: 35px;
		line-height: 35px;
		background: #f2f4fd;

		.orderID {
			margin-left: 15px;
		}

		.shopname {
			.e-image {
				margin-left: 31px;
				margin-right: 5px;
			}
		}
	}

	.item-content {
		padding: 6px 0;
		border: 1px solid #f2f4fd;

		.item-content-left {
			width: 420px;
			height: 90px;
		}

		.goods-pic {
			width: 65px;
			height: 65px;
			background: #ffffff;
			border-radius: 10px;
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.156862745098039);
			margin-right: 19px;
			margin-left: 15px;
		}

		.productionPlant {
			height: 16px;
			line-height: 16px;
			margin-top: 8px;
			margin-bottom: 5px;
		}

		.label {
			background: linear-gradient(to right, #ff0000, #ff9999);
			width: 56px;
			height: 16px;
			line-height: 16px;
		}

		.delivery {
			width: 36px;
			height: 15px;
			line-height: 15px;
		}

		.engineering {
			color: #aeaeae;
		}

		.operation {
			width: 148px;
			border-left: 1px solid #f2f4fd;

			.detailbtn {
				width: 75px;
				height: 24px;
				line-height: 24px;
				border-radius: 2px;
				text-align: center;
				margin: 0 auto;
			}

			.detailbPlus {
				width: 78px;
				height: 26px;
				line-height: 26px;
				border-radius: 2px;
				text-align: center;
			}
		}
	}

	.detailbPlus-box {
		margin: 15px auto;
		width: 75px;
	}

	.line-height23 {
		line-height: 23px;
	}

	.line-height90 {
		line-height: 90px;
	}

	.commonBox {
		width: 123px;
		height: 90px;
		text-align: center;
		border-left: 1px solid #f2f4fd;
	}

	.item-bottom {
		margin: 12px 15px 0;
		background: #fff7ee;
		color: #ff9900;
		height: 40px;
		line-height: 40px;

		div {
			flex: 1;
			text-align: center;
		}
	}

	.detailbtn:hover {
		cursor: pointer;
	}

	.businessBuy-content {
		width: 1100px;
		overflow: hidden;

		.businessBuy-Items {
			width: 201px;
			height: 273px;
			background: #f1f1fa;
			margin: 0 15px;

			.businessBuy-ItemsBox {
				width: 175px;
				height: 175px;
				margin: 13px 14px 0 14px;
			}
		}

		.businessBuy-Items:first-child {
			margin: 0;
		}

		.businessBuy-Items:last-child {
			margin: 0;
		}
	}

	.my-buy {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
	}

	.my-buy-Num {
		position: absolute;
		top: 90px;
		left: 22px;
		z-index: 9999;
	}

	::v-deep .el-tabs__nav-wrap::after {
		display: none;
	}

	::v-deep .el-tabs__nav {
		background: #ffffff;
	}

	::v-deep .el-tabs__item {
		width: 80px;
		text-align: center;
		padding: 0;
	}

	::v-deep .el-tabs__active-bar {
		width: 80px !important;
	}

	.yijia-footer {
		.yijia-footer-content {
			width: 100%;
			height: 46px;
			margin: 0 auto 15px auto;

			.yijia-footer-item {
				width: calc(100% / 4);
				height: 46px;
				padding-left: 47px;

				.bgc-blue {
					width: 46px;
					height: 46px;
					border-radius: 13px;
				}
			}
		}
	}

	.margin-top-3px {
		margin-top: -3px;
	}

	::v-deep .el-breadcrumb__inner.is-link {
		font-weight: 400;
		color: #999999;
	}

	::v-deep .el-breadcrumb__inner {
		color: #999999;
	}
</style>
