<template>
	<!-- 审核中页面 -->
	<div :class="themeClass" v-loading="loading">
		<div class="yijia-content width-fill" v-loading="loading">
			<div class="padding20-t-lr">
				<div class="flex-row-center-center" v-if="list.length == 0">
					<svg-icon icon-class="NoContent" />
				</div>
				<div class="item margin-b-13" v-for="(item, index) in list" :key="index">
					<div class="border1-f2f4fd">
						<div class="item-title flex-row-space-between-center font-size12">
							<div class="flex-row">
								<div class="time padding-l-15">
									申请日期:&nbsp;{{ item.fCreateTime }}
								</div>
								<div class="orderID">
									询价单号:&nbsp;<span class="color-theme">{{
                    item.fBillNumber
                  }}</span>
								</div>
								<div class="shopname flex-row-align-center">
									<i class="iconfont icon-dianpu4 color-theme"></i>
									<!-- <el-image style="width: 12px; height: 12px" :src="item.fShopLogoPath" :fit="fill"></el-image> -->
									<div class="hide-text width-500" :title="item.fShopName">
										{{ item.fShopName }}
									</div>
								</div>
								<div class="time padding-l-15">
									价格有效期:&nbsp;{{ item.fBeginTime }}
									<span v-if="item.fEndTime != ''">~&nbsp;&nbsp;{{ item.fEndTime }}</span>
								</div>
							</div>
							<div class="operation flex-row-space-between-center margin-r-13">
				<!-- 				<div class="font-size12 backgroundcolor-theme bg-fff border-color-theme detailbtn font-color-fff" style="margin-right: 10px"
								 v-if="item.fStatus == 1 && item.fApproveStatus == 254 && item.fCustomerApproveStatus == 0" @click="agree(item.fInquiryApplyID)">
									同意
								</div>
								<div class="font-size12 backgroundcolor-theme bg-fff border-color-theme detailbtn font-color-fff" style="margin-right: 10px"
								 v-if="item.fStatus == 1 && item.fApproveStatus == 254 && item.fCustomerApproveStatus == 0" @click="disagree(item.fInquiryApplyID)">
									不同意
								</div>
								<div class="font-size12 backgroundcolor-theme bg-fff border-color-theme detailbtn font-color-fff" style="margin-right: 10px"
								 v-if="item.fStatus == 0 && item.fApproveStatus == 255" @click="afreshBuyingPrice(item.fInquiryApplyID)">
									重新议价
								</div> -->
								<div class="font-size12 color-theme bg-fff border-color-theme detailbtn" @click="AuditBuyingPriceDetail(item.fInquiryApplyID)">
									详情
								</div>
							</div>
						</div>
						<div class="item-content bg-fff flex-row-space-between-center">
							<div class="defaultHeight" :class="{ autoHeight: item.checked }">
								<div class="flex-row-align-center" v-for="(item1, index1) in item.details" :key="index1">
									<div class="flex-row-space-between">
										<div class="flex-row-align-center item-content-left">
											<div class="goods-pic flex-row-align-center text-center">
												<img :src="item1.fPicturePath" alt="" width="100%" />
											</div>
											<div>
												<div class="productionPlant font-size13">
													{{ item1.fGoodsFullName }}
												</div>
												<div sclass="flex-row ">
													<div class="delivery color-theme border-color-theme text-center margin5-0 font-size12">
														{{ item1.fDelivery }}
													</div>
												</div>
											</div>
										</div>
										<div class="item-content-right flex-row">
											<div class="flex-row">
												<!-- <div class="payment commonBox line-height90" :class="item1.fDetailStatus == 1 ? 'font-color-ae' : ''">
													{{ item1.fSaleAmount }}吨
												</div> -->
												<div class="payment commonBox line-height23 border-1-r-f2f4fd" 
										      >
													<div class="margin25-auto">
											<!-- 			<span class="font-size12">{{
										          item1.fDetailStatusName
										        }}</span> -->
													<!-- 	<div class="font-weight700 font-size13" style="color: #333;">
															¥{{ item1.fApplyGoodsPrice }}元/{{item1.fUnitName}}
														</div> -->
														<span class="font-size12" v-if="item.fCustomerApproveStatusName =='同意'">{{item.fCustomerApproveStatusName}}</span>
														<span class="font-size12" style="color: #333;" v-if="item.fCustomerApproveStatusName =='不同意'">{{item.fCustomerApproveStatusName}}</span>
														<span class="font-size12" style="color: #333;" v-if="item.fApproveStatusName =='审核中'">{{item.fApproveStatusName}}</span>
														<!-- <div class="font-weight700 font-size13" style="color: #333;">
															¥{{ item1.fApplyGoodsPrice }}元/{{item1.fUnitName}}
														</div> -->
														<!-- 已审核。同意 -->
														<div class="font-weight700 font-size13"  v-if="item.fApproveStatusName == '已审核' &&item.fCustomerApproveStatusName == '同意'">
															
															¥{{ item1.fApplyGoodsPrice }}元/{{item1.fUnitName}}
														</div>
														<!-- 已审核。不同意 -->
														<div class="font-weight700 font-size13" style="color :#333"  v-if="item.fApproveStatusName == '已审核' &&item.fCustomerApproveStatusName == '不同意'">
															
															¥{{ item1.fApplyGoodsPrice }}元/{{item1.fUnitName}}
														</div>
														<!-- 审核中 -->
														<div class="font-weight700 font-size13" style="color :#333"  v-if="item.fApproveStatusName == '审核中'">
															
															¥{{ item1.fApplyGoodsPrice }}元/{{item1.fUnitName}}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style="clear: both" v-show="item.details.length > 2" class="margin-b-10">
						<div class="showBtn font-size12 text-center pointer" @click="showMore(index)" v-if="item.checked == false">
							展开 {{ item.details.length - 2 }} 件商品
						</div>
						<div class="showBtn font-size12 text-center pointer" @click="showMore(index)" v-if="item.checked">
							收起列表
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pagination flex-row-center-flex-end">
			<el-pagination background layout="total,prev, pager, next" :page-size="maxResultCount" :total="total"
			 @current-change="handleCurrentChange"></el-pagination>
		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	// import uploadFile from "@/utils/uploadFile";
	export default {
		name: "AuditBuyingPrice",
		data() {
			return {
				fill: "cover",
				maxResultCount: 10, //每页显示条数
				page: 1, //页数
				list: [],
				total: 0, // 商品总量
				loading: false,
				autoHeightVariable: false,
				showMoreText: false,
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.getAuditList();
		},
		methods: {
			getAuditList() {
				this.loading = true;
				this.ApiRequestPost("api/mall/ebsale/b2b-inquiry-apply/get-audit-list", {
					filter: "",
					maxResultCount: this.maxResultCount,
					skipCount: (this.page - 1) * this.maxResultCount,
				}).then(
					(res) => {
						console.log("获取审核中列表", res.obj);
						this.total = res.obj.totalCount;
						for (let item of res.obj.items) {
							item.checked = false;
							item.fCreateTime = this.getTime(item.fCreateTime);
							item.fModifyTime = this.getTime(item.fModifyTime);
							item.fEndTime = this.getDate(item.fEndTime);
							item.fBeginTime = this.getDate(item.fBeginTime);
						}
						this.list = res.obj.items;
						this.loading = false;
					},
					(error) => {}
				);
			},
			showMore(index) {
				this.autoHeightVariable = !this.autoHeightVariable;
				this.list.forEach((item, index1) => {
					if (index == index1) {
						item.checked = !item.checked;
					}
				});
				this.showMoreText = !this.showMoreText;
			},
			//分页点击事件
			handleCurrentChange(val) {
				this.page = val;
				this.getAuditList();
			},
			//客户同意议价结果
			agree(aid) {
				this.ApiRequestPut("api/mall/ebsale/b2b-inquiry-apply/confirm", {
					fInquiryApplyID: aid
				}).then(
					(res) => {
						this.getAuditList();
					},
					(error) => {}
				);
			},
			//客户不同意议价结果
			disagree(aid) {
				this.ApiRequestPut("api/mall/ebsale/b2b-inquiry-apply/disagree", {
					fInquiryApplyID: aid
				}).then(
					(res) => {
						this.getAuditList();
					},
					(error) => {}
				);
			},
			//议价单详情
			AuditBuyingPriceDetail(detailid) {
				this.$router.push({
					name: "buyingPriceDetails",
					params: {
						id: detailid,
					},
				});
			},
			//重新议价
			afreshBuyingPrice(aid) {
				this.$router.push({
					name: "applicationPricetab",
					query: {
						id: aid,
						edit: true,
					},
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.item {
		width: 1060px;
	}

	.detailbtn {
		width: 71px;
		height: 24px;
		line-height: 24px;
		border-radius: 2px;
		text-align: center;
		margin: 0 auto;
	}

	.border1-f2f4fd {
		border: 1px solid #f2f4fd;
	}

	.padding-b-7 {
		padding-bottom: 7px;
	}

	.margin-b-14 {
		margin-bottom: 14px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.backgroundcolor-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.margin-r-35 {
		margin-right: 58px;
	}

	.color00CC0 {
		color: #00cc00;
	}

	.margin5-0 {
		margin: 5px 5px;
	}

	// .padding20-t-lr {
	//   padding: 20px 20px 50px 20px;
	// }

	.margin25-auto {
		margin: 25px auto;
	}

	// .border1-l-f2f4fd{
	//   border-left: 1px solid #f2f4fd;
	// }
	.defaultHeight {
		max-height: 180px;
		overflow: hidden;
		border-right: 1px solid #f2f4fd;
	}

	.autoHeight {
		max-height: 100% !important;
		overflow: hidden;
	}

	.yijia-content {
		width: 1100px;
		min-height: 700px;
		background: #f8f9fe;
		margin-bottom: 100px;
	}

	.showBtn {
		width: 112px;
		height: 29px;
		line-height: 29px;
		background-color: rgba(227, 232, 251, 1);
		border-radius: 0 0 10px 10px;
		color: #6980e7;
		margin: 0 auto;
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.font-color-FF0033 {
		color: #ff0033;
	}

	.item-title {
		box-sizing: border-box;
		height: 35px;
		line-height: 35px;
		background: #f2f4fd;

		.orderID {
			margin-left: 15px;
		}

		.shopname {
			margin-left: 13px;

			.e-image {
				margin-left: 31px;
				margin-right: 5px;
			}

			.width-500 {
				padding-left: 5px;
				width: 150px;
			}
		}
	}

	.item-content {
		.item-content-left {
			width: 936px;
			height: 90px;
		}

		.goods-pic {
			width: 65px;
			height: 65px;
			background: #ffffff;
			border-radius: 10px;
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.156862745098039);
			margin-right: 19px;
			margin-left: 15px;
			overflow: hidden;
		}

		.productionPlant {
			line-height: 10px;
			margin-bottom: 11px;
		}

		.delivery {
			width: max-content;
			padding: 0 5px;
			height: 15px;
			line-height: 15px;
		}

		.engineering {
			color: #aeaeae;
		}
	}

	.line-height23 {
		line-height: 23px;
	}

	.line-height90 {
		line-height: 90px;
	}

	.commonBox {
		width: 123px;
		height: 90px;
		text-align: center;
		border-left: 1px solid #f2f4fd;
	}

	.detailbtn:hover {
		cursor: pointer;
	}

	.businessBuy-content {
		width: 1100px;
		overflow: hidden;

		.businessBuy-Items {
			width: 201px;
			height: 273px;
			background: #f1f1fa;
			margin: 0 15px;

			.businessBuy-ItemsBox {
				width: 175px;
				height: 175px;
				margin: 13px 14px 0 14px;
			}
		}

		.businessBuy-Items:first-child {
			margin: 0;
		}

		.businessBuy-Items:last-child {
			margin: 0;
		}
	}
</style>
